/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
video {
  float: left;
  width: 100%;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 15px;
  margin-bottom: 15px;
}
.lead {
  margin-bottom: 0 !important;
}
#edit .line {
  padding: 4px 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #627472;
  color: #627472;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #627472 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
  height: auto;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 3001;
  color: #627472;
  font-family: 'filson-pro', helvetica, sans-serif;
  line-height: 1.57142857;
  font-size: 14px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #627472;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: 700;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #627472;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #e9ecec;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #F64257;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #F64257;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 700;
}
.table td {
  padding: 5px 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 700;
}
@media (max-width: 767px) {
  .table {
    font-size: 1.82529335vw;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(246, 66, 87, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1284px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  float: left;
  height: 50px;
}
.home {
  position: absolute;
  z-index: 212;
  float: left;
}
.home--big {
  display: block;
}
.home--small,
.home--small-mobile {
  display: none;
}
.logo {
  height: 100%;
  width: auto;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #627472;
  font-family: 'filson-pro', helvetica, sans-serif;
  line-height: 1.57142857;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.section {
  position: relative;
  z-index: 10;
  float: left;
  width: 100%;
  background-color: #fff;
}
.section .desk {
  z-index: 20;
}
.section--south {
  box-shadow: 0px 30px 20px rgba(0, 0, 0, 0.4);
}
.section--footer {
  position: fixed;
  z-index: 9;
  left: 0;
}
.navbar {
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100%;
  transition: background 250ms, box-shadow 250ms, height 250ms;
}
.cb-scroll-triggered--active .navbar {
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.headercontent {
  position: relative;
  z-index: 10;
  float: left;
}
.headercontent:before,
.headercontent:after {
  content: none;
}
.downlink {
  display: none;
}
#expo {
  position: relative;
  z-index: 1;
  height: 0;
}
#slides {
  position: relative;
  overflow: hidden;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides a {
  display: block;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#expo div.link {
  display: none;
}
.section--footer {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  background-color: #4A5352;
  color: #fff;
}
.section--footer h2 {
  font-weight: 700;
}
.section--footer a {
  color: #F64257;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #fff;
}
.section--footer .footer-inner {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.section--footer .footer-inner:before,
.section--footer .footer-inner:after {
  content: none;
}
.section--footer .left,
.section--footer .right {
  float: left;
  width: 100%;
}
.section--footer .vcard .org {
  font-weight: 700;
}
.section--footer .service_contact {
  display: block;
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid #F64257;
  border-radius: 21px;
  padding: 7px 35px;
  background-color: #F64257;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
  text-transform: lowercase;
  transition: background 250ms, color 250ms;
}
.section--footer .service_contact:hover,
.section--footer .service_contact:focus {
  background-color: transparent;
  color: #F64257;
}
.section--footer .services {
  align-self: flex-end;
  float: left;
  width: 100%;
  margin-bottom: 25px;
  border-top: 1px solid #8C9795;
  padding-top: 15px;
  font-size: 14px;
  font-size: 1.4rem;
}
.section--footer .services a {
  color: #fff;
}
.section--footer .services .copy {
  padding: 4px 0;
}
.section--footer .services #services,
.section--footer .services #cmsbox {
  padding: 4px 0;
}
.section--footer .services #services .meta,
.section--footer .services #cmsbox .meta {
  border-right: 1px solid #fff;
  padding: 0 10px;
}
.section--footer .services #services .meta:first-child,
.section--footer .services #cmsbox .meta:first-child {
  padding-left: 0;
}
.section--footer .services #services .meta:last-child,
.section--footer .services #cmsbox .meta:last-child {
  border-right: 0;
  padding-right: 0;
}
.section--footer .services #cmsbox {
  margin-top: 15px;
}
.section--footer .services #cmsbox .meta {
  display: inline-block;
}
h1 {
  font-weight: 500;
}
h1 strong {
  font-weight: 800;
}
h2 {
  font-weight: 500;
}
h2 strong {
  font-weight: 800;
}
.cb-layout1 .north .pure h2,
.cb-layout2 .north .pure h2 {
  color: #F64257;
}
h3,
h4 {
  color: #F64257;
  font-weight: 700;
}
.north .seam h3,
.north .seam h4 {
  color: #438273;
}
.south h3,
.south h4,
.farwest h3,
.farwest h4 {
  color: #fff;
}
.c2 .farnorth h3,
.c2 .farnorth h4 {
  color: #438273;
}
h3 sup,
h4 sup {
  font-size: 60%;
}
.loud {
  font-weight: 200;
}
strong {
  font-weight: 700;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
a {
  color: #F64257;
  text-decoration: none;
  transition: color 250ms;
}
a:hover,
a:focus {
  color: #438273;
}
.area {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.area.main,
.area.base {
  counter-reset: section;
}
.area.main .unit.seam,
.area.base .unit.seam {
  text-align: center;
}
.area.main .unit.flat,
.area.base .unit.flat,
.area.main .unit.edge,
.area.base .unit.edge {
  box-sizing: border-box;
  overflow: hidden;
  counter-increment: section;
}
.area.main .unit.flat .body,
.area.base .unit.flat .body,
.area.main .unit.edge .body,
.area.base .unit.edge .body {
  position: relative;
  box-sizing: border-box;
  padding: 10px 30px;
}
.area.main .unit.flat .body::before,
.area.base .unit.flat .body::before,
.area.main .unit.edge .body::before,
.area.base .unit.edge .body::before {
  content: "";
  position: absolute;
  right: -28px;
  top: 0;
  width: 55px;
  height: 95px;
  background-color: #F64257;
  transform: skewX(30deg);
  box-shadow: -4px 3px 6px rgba(0, 0, 0, 0.27);
}
.area.main .unit.flat .body:after,
.area.base .unit.flat .body:after,
.area.main .unit.edge .body:after,
.area.base .unit.edge .body:after {
  content: counter(section);
  position: absolute;
  right: 12px;
  top: 15px;
  color: #fff;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 700;
  text-align: right;
}
.area.main .unit.flat .body,
.area.base .unit.flat .body {
  background-color: #F4F5F5;
}
.area.main .unit.edge .body,
.area.base .unit.edge .body {
  background-color: #627472;
  color: #fff;
}
.north {
  text-align: center;
}
.north img {
  margin: 0 auto;
}
.north .unit {
  box-sizing: border-box;
  margin-top: 25px;
  margin-bottom: 25px;
  overflow: hidden;
}
#view .north .unit .part.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
#view .north .unit .part.link .open {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.cb-layout1 .north img {
  max-width: 135px !important;
}
.cb-layout1 .north .unit {
  padding: 30px 30px;
}
.cb-layout1 .north .unit:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 10px solid #F64257;
  transition: border 250ms;
}
.cb-layout1 .north .unit:after {
  content: "";
  position: absolute;
  right: -43px;
  top: 0;
  width: 85px;
  height: 146px;
  background-color: #F64257;
  transform: skewX(30deg);
  box-shadow: -4px 3px 6px rgba(0, 0, 0, 0.27);
}
.cb-layout1 .north .unit.seam:before {
  border-color: #438273;
}
.cb-layout1 .north .unit.seam:after {
  background-color: #438273;
}
.cb-layout2 .north .unit.pure,
.cb-layout3 .north .unit.pure {
  padding: 0;
}
.cb-layout2 .north .unit.seam img,
.cb-layout3 .north .unit.seam img,
.cb-layout2 .north .unit.flat img,
.cb-layout3 .north .unit.flat img {
  max-width: 193px !important;
}
.cb-layout2 .north .unit.flat,
.cb-layout3 .north .unit.flat {
  opacity: 0.3;
}
.farnorth .part {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.area.south,
.area.farwest {
  color: #fff;
  text-align: center;
  background-color: #438273;
}
.area.south img,
.area.farwest img {
  margin: 0 auto;
}
.area.south:before,
.area.farwest:before {
  content: "";
  position: absolute;
  z-index: 10;
  right: 0;
  top: -1px;
  width: 100%;
  height: 0;
  padding-bottom: 17.23958333%;
  background: url(/images/bg-unit-top.svg) no-repeat top center;
}
.area.south .unit,
.area.farwest .unit {
  box-sizing: border-box;
  padding-top: 17.23958333%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#view.south--empty .area.south {
  display: none;
}
.area.south .unit {
  background-image: url(/images/bg-unit-1.jpg);
}
.area.south .unit.seam {
  background-image: url(/images/bg-unit-2.jpg);
}
.area.south .unit.flat {
  background-image: url(/images/bg-unit-3.jpg);
}
#view.farwest--empty .area.farwest {
  display: none;
}
.area.farwest .unit {
  background-image: url(/images/bg-unit-4.jpg);
}
.area.farwest .unit.seam {
  background-image: url(/images/bg-unit-5.jpg);
}
.unit.fold {
  box-sizing: border-box;
  border: 2px solid #438273;
  border-radius: 29px;
}
.unit.fold h2 {
  text-align: left;
}
.unit.fold h2 a {
  color: inherit;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold div.more .part:first-child {
  margin-top: 0;
}
.unit.fold .fold-toggle {
  box-sizing: border-box;
  display: block;
  padding-right: 39px;
  background-image: url(/images/fold-more.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 25px 25px;
}
.unit.fold .fold-toggle.fold-toggle--open {
  background-image: url(/images/fold-less.svg);
}
.unit.fold .foot .ctrl {
  display: none;
}
#view .unit.fold .body .more {
  display: block !important;
  max-height: 0;
  overflow: hidden;
  transition: max-height 250ms ease-out;
}
#view .unit.fold.fold--open .body .more {
  max-height: 1000px;
  transition: max-height 500ms ease-in;
}
.unit.form .name {
  padding-bottom: 8px;
  font-weight: 700;
}
.unit.form input,
.unit.form textarea {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
.unit.form input.text,
.unit.form input.sign,
.unit.form input.password,
.unit.form textarea,
.unit.form input.hint,
.unit.form textarea.hint,
.unit.form input.capt {
  border-width: 2px;
  border-radius: 29px;
  padding: 10px 20px;
  box-shadow: none;
  font-weight: 300;
}
.unit.form .foot {
  float: left;
  padding-bottom: 0;
}
.unit.form input.submit,
.unit.form button.cb-hybrid {
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid #F64257;
  border-radius: 21px;
  padding: 7px 35px;
  background-color: #F64257;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
  text-transform: lowercase;
  transition: background 250ms, color 250ms;
  float: right;
  padding-right: 47px;
  background-image: url(/images/arrow-right-white.svg);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 17px 13px;
}
.unit.form input.submit:hover,
.unit.form button.cb-hybrid:hover,
.unit.form input.submit:focus,
.unit.form button.cb-hybrid:focus {
  background-color: transparent;
  color: #F64257;
}
.unit.form input.submit:hover,
.unit.form button.cb-hybrid:hover,
.unit.form input.submit:focus,
.unit.form button.cb-hybrid:focus {
  background-image: url(/images/arrow-right-red.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.text-section:last-child {
  margin-bottom: 0;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
  color: #F64257;
}
.list--numbered li {
  margin-left: 16px;
}
div.load {
  background: none !important;
}
.area:not(.north) div.link .open,
.area:not(.north) div.load .load {
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid #F64257;
  border-radius: 21px;
  padding: 7px 35px;
  background-color: #F64257;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
  text-transform: lowercase;
  transition: background 250ms, color 250ms;
}
.area:not(.north) div.link .open:hover,
.area:not(.north) div.load .load:hover,
.area:not(.north) div.link .open:focus,
.area:not(.north) div.load .load:focus {
  background-color: transparent;
  color: #F64257;
}
.north .seam .area:not(.north) div.link .open,
.north .seam .area:not(.north) div.load .load {
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid #438273;
  border-radius: 21px;
  padding: 7px 35px;
  background-color: #438273;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
  text-transform: lowercase;
  transition: background 250ms, color 250ms;
}
.north .seam .area:not(.north) div.link .open:hover,
.north .seam .area:not(.north) div.load .load:hover,
.north .seam .area:not(.north) div.link .open:focus,
.north .seam .area:not(.north) div.load .load:focus {
  background-color: transparent;
  color: #438273;
}
.area.north .unit div.text .pale {
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid #F64257;
  border-radius: 21px;
  padding: 7px 35px;
  background-color: #F64257;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
  text-transform: lowercase;
  transition: background 250ms, color 250ms;
}
.area.north .unit div.text .pale:hover,
.area.north .unit div.text .pale:focus {
  background-color: transparent;
  color: #F64257;
}
.area.north .unit .seam div.text .pale {
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid #438273;
  border-radius: 21px;
  padding: 7px 35px;
  background-color: #438273;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
  text-transform: lowercase;
  transition: background 250ms, color 250ms;
}
.area.north .unit .seam div.text .pale:hover,
.area.north .unit .seam div.text .pale:focus {
  background-color: transparent;
  color: #438273;
}
.area.north .unit:hover div.text .pale,
.area.north .unit:focus div.text .pale {
  background-color: transparent;
  color: #F64257;
}
.area.north .unit:hover .seam div.text .pale,
.area.north .unit:focus .seam div.text .pale {
  background-color: transparent;
  color: #438273;
}
.area:not(.north) div.link.site .open {
  padding-right: 47px;
  background-image: url(/images/arrow-right-white.svg);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 17px 13px;
}
.area:not(.north) div.link.site .open:hover,
.area:not(.north) div.link.site .open:focus {
  background-image: url(/images/arrow-right-red.svg);
}
#edit .area {
  border-top: 2px dashed #ccc;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.57142857em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #627472;
  background-color: #fff;
  font-weight: normal;
  font-family: 'filson-pro', helvetica, sans-serif;
  font-size: 14px;
  font-size: 1.4pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.57142857em;
  border: 1px solid #627472;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 14px;
  font-size: 1.4pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'filson-pro', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #b1bebc;
}
input.text[disabled],
textarea[disabled] {
  border-color: #6e8280;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #438273;
  box-shadow: 0 0 3px rgba(67, 130, 115, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #59a996;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #59a996;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #F64257;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #f75164;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #f4112c;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.57142857em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
.togglenavigation {
  position: fixed;
  top: 26px;
  right: 30px;
  display: block;
  width: 30px;
  height: 26px;
  z-index: 212;
  cursor: pointer;
  color: #F64257 !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:after {
  content: 'menu';
  position: absolute;
  right: 100%;
  top: 50%;
  padding-right: 20px;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.05em;
}
.togglenavigation:active > span {
  background: #f41b34 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 4px;
  width: 30px;
  margin-top: -2px;
  border-radius: 2px;
  background: currentColor;
}
.togglenavigation > .tline-1,
.togglenavigation > .tline-4 {
  top: 0;
  margin-top: 0;
}
.togglenavigation > .tline-1 {
  width: 22px;
  transition: top 150ms ease 218ms, opacity 150ms ease 218ms;
}
.togglenavigation > .tline-4 {
  top: auto;
  width: 13px;
  bottom: 0;
  transition: bottom 150ms ease 218ms, opacity 150ms ease 218ms;
}
.togglenavigation > .tline-4:after {
  content: "";
  position: absolute;
  right: -8px;
  top: 0;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
}
.togglenavigation > .tline-2,
.togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-1 {
  top: 50%;
  transition: top 150ms ease, opacity 150ms ease;
}
.cb-toggle-target-active .togglenavigation > .tline-4 {
  bottom: 50%;
  transition: bottom 150ms ease, opacity 150ms ease;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5) 150ms;
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  transform: rotate(45deg);
}
#navigation {
  box-sizing: border-box;
  position: fixed;
  z-index: 210;
  left: 0;
  top: 0;
  width: 100%;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  background: #fff;
  overflow: hidden;
  text-align: right;
  transition: all 0.3s ease-in;
}
body.cb-toggle-target-active #navigation {
  height: 100vh;
  overflow: auto;
  transition: all 0.218s ease-out;
}
.navihome {
  float: right;
  display: block;
  color: #F64257;
  font-weight: 700;
  line-height: 1;
  text-transform: lowercase;
  padding: 15px 0;
  font-size: 45px;
  font-size: 4.5rem;
}
@media (max-width: 1023px) {
  .navihome {
    font-size: 2.5rem;
    padding: 20px 0;
  }
}
.navihome:hover,
.navihome:focus {
  color: #F64257;
}
#navigation .languageNavi {
  float: right;
  display: block;
  width: 100%;
  margin-top: 139px;
  border-radius: 21px;
  transition: margin 250ms;
}
.cb-scroll-triggered--active #navigation .languageNavi {
  margin-top: 81px;
}
#navigation .languageNavi > .item {
  display: inline-block;
}
#navigation .languageNavi > .item.init > .menu {
  border-radius: 21px 0 0 21px;
}
#navigation .languageNavi > .item.init.exit {
  display: none;
}
#navigation .languageNavi > .item.exit > .menu {
  border-radius: 0 21px 21px 0;
}
#navigation .languageNavi > .item > .menu {
  box-sizing: border-box;
  display: block;
  width: 55px;
  height: 42px;
  border: 2px solid #F64257;
  padding: 12px 15px;
  color: #F64257;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  text-transform: lowercase;
  transition: background 250ms, color 250ms;
}
#navigation .languageNavi > .item > .menu:hover,
#navigation .languageNavi > .item > .menu:focus,
#navigation .languageNavi > .item > .menu.path {
  background-color: #F64257;
  color: #fff;
}
#navigation .navi.sub1 {
  float: right;
  display: block;
  width: 100%;
  margin-top: 40px;
}
#navigation .navi.sub1 > .item {
  width: 100%;
  float: left;
  position: relative;
}
#navigation .navi.sub1 > .item > .menu {
  display: block;
  color: #F64257;
  padding: 20px 0;
  font-weight: 700;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1;
  text-transform: lowercase;
}
#navigation .vcard {
  float: right;
  width: 100%;
  margin-top: 60px;
}
#navigation .vcard .org {
  font-weight: 500;
}
#social {
  float: right;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
#social .meta {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#social .meta:first-child {
  margin-left: 0;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_facebook {
  background: url(/images/icon-facebook.svg) no-repeat center;
}
#social .meta.service_linkedin {
  background: url(/images/icon-linkedin.svg) no-repeat center;
}
#social .meta.service_instagram {
  background: url(/images/icon-instagram.svg) no-repeat center;
}
.desk {
  max-width: 84%;
}
.home--big {
  top: 10px;
  left: 30px;
  height: 104px;
  visibility: visible;
  opacity: 1;
  transition: height 250ms, opacity 250ms, visibility 250ms;
}
.cb-scroll-triggered--active .home--big {
  height: 73px;
  visibility: hidden;
  opacity: 0;
}
.home--small-mobile {
  top: 10px;
  left: 30px;
  display: block;
  height: 76px;
  visibility: hidden;
  opacity: 0;
  transition: height 250ms, opacity 250ms, visibility 250ms;
}
.cb-scroll-triggered--active .home--small-mobile {
  z-index: 213;
  height: 56px;
  visibility: visible;
  opacity: 1;
}
.navbar {
  height: 136px;
}
.cb-scroll-triggered--active .navbar {
  height: 78px;
}
.section--header {
  padding-top: 136px;
}
.headercontent {
  width: 84%;
  margin-left: 8%;
}
#expo {
  width: 100%;
  padding-bottom: 95.46666667%;
}
.cb-layout3 #expo {
  width: 132px;
  margin: 30px auto 40px;
  padding-bottom: 115px;
}
.wrapper {
  padding-bottom: calc(100vh - 90px);
}
.section--footer {
  top: 90px;
  height: calc(100vh - 90px);
  text-align: center;
}
.section--footer h2 {
  margin-top: 75px;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.2;
}
.section--footer .logo {
  width: 105px;
  margin: 55px auto 0;
}
.section--footer .vcard {
  margin-top: 20px;
}
.section--footer .service_contact {
  margin-top: 35px;
}
.section--footer .services {
  margin-top: 45px;
}
.section--footer .services .copy {
  margin-top: 25px;
  padding: 4px 0;
}
.section--footer .services #services {
  padding: 4px 0;
}
.section--footer .services #cmsbox {
  margin-top: 25px;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1023px) {
  .section--footer .services #cmsbox .meta.service_augenweide {
    display: block;
    border-right-width: 0;
    padding: 0;
  }
}
h1 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5625;
}
h2 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5625;
}
h3 {
  font-size: 35px;
  font-size: 3.5rem;
  line-height: 1.14285714;
}
.farnorth h3 {
  font-size: 45px;
  font-size: 4.5rem;
  line-height: 1.11111111;
}
h4 {
  font-size: 26px;
  line-height: 1.15384615;
}
.text-section--h3 {
  margin-bottom: 20px;
}
.loud {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.area .part {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.area > .slim .part {
  width: 90%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-small.css.map */